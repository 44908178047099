import React, { useState, useEffect } from "react";
import SectionsList from "components/sections/sectionsList";
import OffersService from "network/offers-service";
import { IOfferWallOffer, IOfferWallResponse } from "@bprotsyk/aso-core";
import "./casino.css"
import BackArrow from "assets/back-arrow.png"

export let useToggleIFrameHook: (offer?: IOfferWallOffer) => void

function Casino() {
  const [data, setData] = useState<IOfferWallResponse>();

  const [renderOffer, setRenderOffer] = useState<IOfferWallOffer | null>(null)
  const [iFrameEnabled, setIFrameEnabled] = useState<boolean>(false)

  useToggleIFrameHook = (offer?: IOfferWallOffer) => {
    if (iFrameEnabled) { setRenderOffer(offer ? offer : null) }
    else { window.open(offer?.url, '_blank'); }
  }

  let renderIFrame = renderOffer != undefined && iFrameEnabled

  useEffect(() => {
    let getOffers = async () => {
      const params = new URLSearchParams(window.location.search)
      let appId = params.get('appId')
      let clickId = params.get('clickId')
      let countryCode = params.get('countryCode')
      let iFrameEnabledQuery = params.get('iFrame')

      setIFrameEnabled(iFrameEnabledQuery != undefined)

      if (!countryCode || !appId) return

      try {
        let offersResponseResult = await OffersService.getOffersResponse(appId, clickId || "none", countryCode)

        setData(offersResponseResult.data)
      } catch (e) {
        console.log(e)
      }
    }

    getOffers()
  }, []);

  let body

  if (renderIFrame) {
    body = <div className="iFrameContainer">
      <div className="iFrameHeader" >
        <img className="iFrameBackIcon" src={BackArrow} onClick={() => {
          setRenderOffer(null)
        }} />
        <span className="iFrameCaption" onClick={() => {
          setRenderOffer(null)
        }}>
          {renderOffer?.caption}
        </span>
      </div>
      {renderOffer &&
        <iframe frameBorder="0" className="iFrame" src={renderOffer.url} />
      }
    </div>
  } else {
    body = <div className="sectionsList">
      {data && <SectionsList sections={data.sections} />}
    </div>
  }

  return (body);
}
export default Casino;
