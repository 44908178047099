import http from './http-panel'
import { IOfferWallResponse } from '@bprotsyk/aso-core'

const getOffersResponse = (appId: string, clickId: string, country: string) => {
    return http.get<IOfferWallResponse>(`/web-offers/${appId}?clickId=${clickId}&countryCode=${country}`)
}

const OffersService = {
    getOffersResponse
}

export default OffersService