import React, { useState, useEffect } from "react";
import Casino from "./components/casino/casino";

function App(): JSX.Element {
  return (
    <section className="container">
      <Casino />
    </section>
  );
}

export default App;
