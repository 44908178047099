import PlayButton from "components/buttons/play/playButton";
import "./offer.css"
import { IOfferWallOffer } from "@bprotsyk/aso-core";
import { useToggleIFrameHook } from "components/casino/casino";

interface IOddOfferParams {
    offer: IOfferWallOffer,
    userId: string,
    buttonText: string,
    even: boolean
}

export default (params: IOddOfferParams) => {
    const { offer, buttonText, even } = params

    let emojiContainerPositionStyle = `emoji_container_position${!offer.emojiIcon ? " gone" : ""}`
    let offerListItemStyle = `offer_list_item${!even ? " offer_list_item_first" : ""}`

    return (
        <li
            className={offerListItemStyle}
            key={offer.url}
            style={{ backgroundImage: `url(${offer.backgroundUrl})` }}
            onClick={() => {
                useToggleIFrameHook(offer)
            }}
        >
            <a
                // href={offer.url} target="_blank"
                className="item_link">
                <div className={emojiContainerPositionStyle}>
                    <div className="emoji_container">
                        <span>{offer.emojiIcon}</span>
                    </div>
                    {offer.emojiCaption && (
                        <div className='emoji_caption'><p className='emoji_caption_text'>{offer.emojiCaption}</p></div>
                    )}
                </div>

                <div className='img_container'>
                    <img
                        src={offer.foregroundUrl}
                        className="img_card"
                    />
                </div>
                <PlayButton
                    text={buttonText}
                />
            </a>
        </li>
    );
} 