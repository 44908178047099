import { v4 as uuidv4 } from 'uuid';
import Offer from 'components/offer/offer';
import "./offersList.css"
import { IOfferWallOffer, IOfferWallSection } from '@bprotsyk/aso-core';

interface ISectionParams {
  section: IOfferWallSection
}

export default (params: ISectionParams) => {
  const { section } = params
  const offers = section.offers

  const userId = uuidv4();
  if (!document.cookie) {
    document.cookie = userId;
  }

  return (
    <ul className="offers_list">
      {
        offers.map((offer: IOfferWallOffer) => {
          return <Offer offer={offer} buttonText={section.buttonText} userId={userId} even={offers.length % 2 === 0}/> 
        })
      }
    </ul>
  );
}

