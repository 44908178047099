import "./playButton.css"

interface IPlayButtonParams {
  text: string
}

function playButton(params: IPlayButtonParams): JSX.Element {
  let {text} = params
  return (
    <span className="play_button">
      {text}
    </span>
  );
}
export default playButton;
