import { useState, useEffect } from "react";
import OffersList from "../offers/offersList";
import Title from "./title/title";
import { IOfferWallSection } from "@bprotsyk/aso-core";

interface ISectionsListParams {
  sections: IOfferWallSection[]
}

function SectionsList(params: ISectionsListParams) {
  let { sections } = params
  return (
    <div>
      {sections.map((section: IOfferWallSection) => (
        <div className="list_container">
          <Title title={section.title} />

          <OffersList section={section} />
        </div>
      ))}
    </div>
  );
}
export default SectionsList;
